import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Experience } from '@libs/src/experience/experience.model';
import { Feedback } from '@libs/src/feedback/feedback';
import { PathwayMembershipUnpopulated } from '@libs/src/models/pathway-membership.interface';
import {
  ProgramMembership,
  ProgramMembershipStatus,
} from '@libs/src/models/program-membership.model';
import { Program } from '@libs/src/models/program.model';
import { SurveyResponse } from '@libs/src/models/survey-response.model';
import { Survey } from '@libs/src/models/survey.model';
import { PreregistrationAccount } from '@main-client/src/app/invite-csv/invite-csv-view/invite-csv-view.interface';
import { sanitizeGetParams } from '@main-client/src/app/opportunity/opportunity.helper';

import { UserFeedback } from '../feedback/user-feedback';
import {
  Account,
  AccountGroupMembership,
  GcmSubscription,
} from '../models/account.model';
import { Group } from '../models/group.model';
import { Match, MatchConfig, MatchCycle } from '../models/match.interfaces';

const ACCOUNT_API_URL = '/api/account';
const ACCOUNT_V2_PREFIX = '/api/v2/account';
const ACCOUNT_V3_PREFIX = '/api/v3/account';
const AccountGroupApiUrl = (group: Group) =>
  `${ACCOUNT_V2_PREFIX}/groups/${group._id}`;
const AccountGroupMatchApiUrl = (group: Group, match: Match) =>
  `${AccountGroupApiUrl(group)}/matches/${match._id}`;
const apiV3AccountProgramsUrl = '/api/v3/account/programs';

@Injectable({ providedIn: 'root' })
export class AccountBackend {
  v2 = {
    getAccountGroupsMatchFeedbacks: (
      group: Group,
      params: { [key: string]: any },
    ) => {
      return this.http.get<{ feedbacks: Feedback[] }>(
        `${ACCOUNT_V2_PREFIX}/groups/${group._id}/match_feedbacks`,
        { params },
      );
    },
    getAccountGroupsMatchFeedbackSubmissions: (group: Group, match: Match) =>
      this.http.get(
        `${ACCOUNT_V2_PREFIX}/groups/${group._id}/matches/${match._id}/feedback_submissions`,
      ),
    getAccountOpportunitySubmissions: (params: {
      groupId: string;
      limit?: number;
      skip?: number;
    }) => {
      return this.http.get(`${ACCOUNT_V2_PREFIX}/opportunity-submissions`, {
        params: sanitizeGetParams(params),
      });
    },
    getAccountLocaleConfig: () => {
      return this.http.get<{ localeConfig: Account['locale_config'] }>(
        `${ACCOUNT_V2_PREFIX}/locale-config`,
      );
    },
  };
  v3 = {
    getAccountGroupsMatches: (group: Group, params: { [key: string]: any }) => {
      return this.http.get(`${ACCOUNT_V3_PREFIX}/groups/${group._id}/matches`, {
        params,
      });
    },
    getAccountGroupsProgramMemberships: (group: Group) => {
      return this.http.get<{ programMemberships: ProgramMembership[] }>(
        `${ACCOUNT_V3_PREFIX}/groups/${group._id}/program_memberships`,
      );
    },
    getAccountGroupsProgramMembershipById: (
      group: Group,
      programId: string,
    ) => {
      return this.http.get<{ programMembership: ProgramMembership }>(
        `${ACCOUNT_V3_PREFIX}/groups/${group._id}/program_memberships/${programId}`,
      );
    },
    patchAccountGroupsProgramMembershipById: (
      group: Group,
      programId: string,
      body: { [key: string]: any },
    ) => {
      return this.http.patch<{ programMembership: ProgramMembership }>(
        `${ACCOUNT_V3_PREFIX}/groups/${group._id}/program_memberships/${programId}`,
        body,
      );
    },
    patchAccountPathwayMemberships: (
      pathwayMembershipId: PathwayMembershipUnpopulated['_id'],
      body: Partial<PathwayMembershipUnpopulated>,
    ) =>
      this.http.patch<{ pathwayMembership: PathwayMembershipUnpopulated }>(
        `${ACCOUNT_V3_PREFIX}/pathway_memberships/${pathwayMembershipId}`,
        body,
      ),
    patchAccountPathwayMembershipsForNonUsers: (
      pathwayMembershipId: PathwayMembershipUnpopulated['_id'],
      body: Partial<PathwayMembershipUnpopulated>,
      headers: HttpHeaders,
    ) =>
      this.http.patch<{ pathwayMembership: PathwayMembershipUnpopulated }>(
        `${ACCOUNT_V3_PREFIX}/pathway_memberships/${pathwayMembershipId}`,
        body,
        { headers },
      ),
    postAccountPathwayMemberships: (body: { pathwayToken: string }) =>
      this.http.post<{ pathwayMembership: PathwayMembershipUnpopulated }>(
        `${ACCOUNT_V3_PREFIX}/pathway_memberships`,
        body,
      ),
    getAccountExperiences: (
      group: Group,
      params: { [key: string]: any } = {},
    ) => {
      return this.http.get<{
        experiences: Experience[];
        totalCount: number;
      }>(`${ACCOUNT_V3_PREFIX}/groups/${group._id}/experiences`, {
        params,
      });
    },
    postProgramMembershipOptIn: (
      group: Group,
      programId: string,
      body: { status: ProgramMembershipStatus },
    ) =>
      this.http.post<{ programMembership: ProgramMembership }>(
        `${ACCOUNT_V3_PREFIX}/groups/${group._id}/program/${programId}/opt-in`,
        body,
      ),
    postProgramMembershipOptOut: (
      group: Group,
      programId: string,
      body: { status: ProgramMembershipStatus },
    ) =>
      this.http.post<{ programMembership: ProgramMembership }>(
        `${ACCOUNT_V3_PREFIX}/groups/${group._id}/program/${programId}/opt-out`,
        body,
      ),
    getProgramSurveyResponse: (programId: string, surveyId: string) => {
      return this.http.get<{ surveyResponse: SurveyResponse[] }>(
        `${apiV3AccountProgramsUrl}/${programId}/surveys/${surveyId}/survey_responses`,
      );
    },
    patchProgramSurveyResponse: (
      programId: Program['_id'],
      surveyId: Survey['_id'],
      surveyResponseId: SurveyResponse['_id'],
      body: { match: Match['_id']; user_feedbacks: UserFeedback[] },
    ) =>
      this.http.patch<{ surveyResponse: SurveyResponse }>(
        `${apiV3AccountProgramsUrl}/${programId}/surveys/${surveyId}/survey_response/${surveyResponseId}`,
        body,
      ),
    postProgramSurveyResponse: (
      programId: string,
      surveyId: string,
      body: { match: string; user_feedbacks: UserFeedback[] },
    ) => {
      return this.http.post<{ surveyResponse: SurveyResponse }>(
        `${apiV3AccountProgramsUrl}/${programId}/surveys/${surveyId}/survey_response`,
        body,
      );
    },
    getAccountPreregistration: () => {
      return this.http.get<{ preregistration: PreregistrationAccount }>(
        `${ACCOUNT_V3_PREFIX}/pre-registration`,
      );
    },
  };

  constructor(private http: HttpClient) {}

  getSubscriptionSettings(unsubscribeToken: string) {
    return this.http.get(
      `${ACCOUNT_API_URL}/subscription-settings/${unsubscribeToken}`,
    );
  }

  postSubscriptionSettings(unsubscribeToken: string, update: any) {
    return this.http.post(
      `${ACCOUNT_API_URL}/subscription-settings/${unsubscribeToken}`,
      update,
    );
  }

  getAccountGroupsMatches(group: Group) {
    return this.http.get(`${AccountGroupApiUrl(group)}/matches`);
  }

  getAccountGroupsMatchesById(group: Group, match: Match) {
    return this.http.get<Match[]>(`${AccountGroupMatchApiUrl(group, match)}`);
  }

  getAccountGroupsMatchesFeedback(group: Group, match: Match, params = {}) {
    return this.http.get<{ feedback: Feedback }>(
      `${AccountGroupMatchApiUrl(group, match)}/feedback`,
      {
        params,
      },
    );
  }
  postAccountGroupsMatchesFeedback(group: Group, match: Match, feedback: any) {
    return this.http.post(
      `${AccountGroupMatchApiUrl(group, match)}/feedback`,
      feedback,
    );
  }

  getAccountGroupsMatchConfig(
    group: Group,
    params?: {
      limit?: string;
      skip?: string;
      status?: MatchConfig['status'] | MatchConfig['status'][];
    },
  ) {
    return this.http.get<{ count: number; items: MatchConfig[] }>(
      `${AccountGroupApiUrl(group)}/match_configs`,
      {
        params,
      },
    );
  }

  getAccountGroupsCustomSegmentConfig(
    group: Group,
    params?: {
      limit?: string;
      skip?: string;
      status?: MatchConfig['status'] | MatchConfig['status'][];
    },
  ) {
    return this.http.get<{ count: number; items: MatchConfig[] }>(
      `${AccountGroupApiUrl(group)}/custom_segment_configs`,
      {
        params,
      },
    );
  }

  getAccountGroupsMatchCycles(group: Group, params?: any) {
    return this.http.get<{ count: number; items: MatchCycle[] }>(
      `${AccountGroupApiUrl(group)}/match_cycles`,
      { params },
    );
  }

  getAccountGroupsMatchCyclePreview(group: Group) {
    return this.http.get(`${AccountGroupApiUrl(group)}/match_cycles/preview`);
  }

  patchAccount(update: { [key: string]: any }) {
    return this.http.patch<{ data: Account; message: string }>(
      ACCOUNT_API_URL,
      update,
    );
  }

  putAccountGroups(group: Group, update: any = {}) {
    return this.http.put<AccountGroupMembership[]>(
      AccountGroupApiUrl(group),
      update,
    );
  }

  putAccountPendingGroups(group: Group, update: any) {
    return this.http.put(
      `${ACCOUNT_V2_PREFIX}/pending_groups/${group._id}`,
      update,
    );
  }

  putAccountGroupMatch(group: Group, match: Match, update: any) {
    return this.http.put(AccountGroupMatchApiUrl(group, match), update);
  }

  postAccountGroupsFeedback(group: Group, feedback: any) {
    return this.http.post(`${AccountGroupApiUrl(group)}/feedback`, feedback);
  }

  putAccountGcmSubscriptions(gcmSubscription: GcmSubscription) {
    return this.http.put(`${ACCOUNT_API_URL}/gcm_subscriptions`, {
      gcmSubscription,
    });
  }

  resetPassword(credentials: { email: string }) {
    return this.http.post(`${ACCOUNT_API_URL}/reset-password`, credentials);
  }

  setPassword(credentials: {
    password: string;
    confirmPassword: string;
    passwordResetToken: string;
  }) {
    return this.http.post(`${ACCOUNT_API_URL}/set-password`, credentials);
  }

  resendSignUpEmail() {
    return this.http.post(`${ACCOUNT_API_URL}/resend-signup-email`, {});
  }

  verifyEmail(emailVerificationToken: string) {
    return this.http.put(`${ACCOUNT_API_URL}/verify-email`, {
      emailVerificationToken,
    });
  }

  postFeatureSeen(featureSeenIdentifier: string) {
    return this.http.post(
      `${ACCOUNT_API_URL}/feature-seen/${featureSeenIdentifier}`,
      {},
    );
  }
}
